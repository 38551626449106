import React, { useState } from 'react'

import { NewsletterInnerWrapper, NewsletterWrapper } from './newsletter.style'

type NewsletterProps = {};

const Newsletter: React.FunctionComponent<NewsletterProps> = ({ ...props }) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  }

  return (
    <NewsletterWrapper {...props}>
      <NewsletterInnerWrapper>
        {/* <NewsletterTitle>
          Stay curious! Subscribe to <em>DailyCoding Digest</em>.
        </NewsletterTitle>
        <NewsletterDescription>
          You'll receive up to one email per week with fresh news and articles from ReactNative/Expo world.
        </NewsletterDescription>

        <NewsletterInputWrapper onSubmit={handleSubmit}>
          {success ? (
            <SuccessMessage>{success} 🙂</SuccessMessage>
          ) : (
            <>
              <Input
                type="email"
                name="email"
                placeholder="Write your email here"
                onChange={handleChange}
                value={email}
                required={true}
              />
              <Button title="Subscribe" type="submit" />
            </>
          )}
        </NewsletterInputWrapper>
        {error && (
          <ErrorMessage
            dangerouslySetInnerHTML={{ __html: `<span>*</span>${error}` }}
          />
        )} */}
      </NewsletterInnerWrapper>
    </NewsletterWrapper>
  );
};

export default Newsletter;
